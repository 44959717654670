import { Component, Input, OnInit } from '@angular/core';
import { IDiccionarios, IItem } from 'src/app/models/interfaces-apis/diccionarios';

import { TranslateService } from '@ngx-translate/core';
import { EColorEstado, EEstadoPerfil, ERuta, ETipoAlerta } from 'src/app/models/enums.enum';
import { IPeticionAdmin, IRespuestaBuscarEmpresa, IRespuestaListaUsuario } from 'src/app/models/interfaces-apis/administracion';
import { AdminService } from 'src/app/servicios/admin.service';
import { AlertaService } from 'src/app/shared/alerta.service';
import { Util } from 'src/app/shared/util';
import { IExpresion } from 'src/app/models/General/expresion-regular';
import { IPais } from 'src/app/models/general/pais';
import { ICodigoComunidad } from 'src/app/models/General/comunidad-autonoma';
import { IFichaCliente } from 'src/app/models/interfaces-apis/ficha_cliente';
import { GeneralService } from 'src/app/servicios/general.service';
import { ItemConverter } from 'src/app/shared/item-converter';


@Component({
  selector: 'app-modulo-administracion',
  templateUrl: './modulo-administracion.component.html',
  styleUrls: ['./modulo-administracion.component.scss']
})
export class ModuloAdministracionComponent implements OnInit {

  diccionarios: IDiccionarios[] = []

  buscarNombreEmpresa: string = '';
  buscarEstadoUsuario: string = '';
  empresas: IRespuestaBuscarEmpresa[] = null;
  empresa: IRespuestaBuscarEmpresa;
  usuarios: IRespuestaListaUsuario[];
  usuariosFiltrados: IRespuestaListaUsuario[] = [];
  filtroUsuario: IRespuestaListaUsuario[] = [];
  guardarEstado: number[] = [];
  estados: any[] = [];
  estadosFiltrados: any[] = [];
  estadoSeleccionado: any[] = [];
  usuarioSeleccionado: any[] = [];
  coloresEstado = EColorEstado;
  peticion: IPeticionAdmin = {
    Cliente: null
  }

  clienteFicha: IFichaCliente = Util.crearInstanciaFichaCliente();

  expresionesRegulares: IExpresion;

  paises: IPais[] = [];

  listaPaises: IItem[] = [];
 
  listaPrefijos: IItem[] = [];

  comunidades: ICodigoComunidad[] = [];

  listaComunidades: IItem[] = [];

  vertabla: boolean = false;

  constructor(private _alertaService: AlertaService, private _adminService: AdminService, private _translate: TranslateService,  private _generalService: GeneralService) { }

  async ngOnInit() {
    this.cargaDiccionario();
    this.obtenerEstados();
    this.expresionesRegulares = await this._generalService.cargarExpresionRegulares();
    this.paises = await this._generalService.cargarPaises();
    let prefijos = Util.filtrarDuplicadosPrefijos(this.paises)
    this.listaPaises = ItemConverter.transformarObjetoToItem(this.paises, 'alpha2', 'name');
    this.listaPrefijos = ItemConverter.transformarObjetoToItem(prefijos, 'prefix', 'prefix');
    this.comunidades = await this._generalService.cargarComunidades();
    this.listaComunidades = ItemConverter.transformarObjetoToItem(this.comunidades, 'Codigo', 'Nombre');
  }

  //Cargamos todos los diccionarios
  cargaDiccionario() {
    this._adminService.cargarDiccionarios().subscribe({
      next: (data: any) => {
        this.diccionarios = data;
      },
    });
  }

  //En caso de tener una empresa seleccionada realizaremos la carga en precliente
  cargarprecliente() {
    if (this.empresa != null) {
      this.peticion.Cliente = this.empresa.Id
      this._alertaService.cargarBbddPrecliente(() => this.cargarpreclientes(this.peticion), this.empresa.Nombre)
    } else {
      this._alertaService.mostrarAlertaConTitulo(this._translate.instant('precliente.alert.porfavorseleccione'), '', ETipoAlerta.Info);
    } 
  }

  // traspasar la informacion de usuarios de un cliente desde la tabla Cliente a Pre Cliente
  cargarpreclientes(peticion: IPeticionAdmin){
    this._adminService.cargarEnPreCliente(this.peticion).subscribe({
      next:(value) => {
        this._alertaService.mostrarAlertaConTitulo(this._translate.instant('precliente.alert.cargado-cantidad', { 'valor': value }), '', ETipoAlerta.Success)
      }
    })
  }

  //En caso de tener una empresa seleccionada llamaremos a sincronziarDatos para enviar a Destinux
  sincronizacion() {
    if (this.empresa != null) {
      this.peticion.Cliente = this.empresa.Id
      this._alertaService.sincronizar(() => this.sincronizarDatos(this.peticion))
    } else {
      this._alertaService.mostrarAlertaConTitulo(this._translate.instant('precliente.alert.porfavorseleccione'), '', ETipoAlerta.Info);
    }
  }

  //Hacemos la llamada para realizar la sincronizacion de cliente desde pre cliente a cliente
  sincronizarDatos(peticion: IPeticionAdmin){
    this._adminService.sincronizar(peticion).subscribe({
      next:(params) => {
        this._alertaService.mostrarAlertaConTitulo(this._translate.instant('precliente.alert.sincronizado-cantidad', { 'valor': params }), '', ETipoAlerta.Success);
      }
    })
  }

  //Cargar la tabla con todos los usuarios de una empresa
  cargarTabla() {
    if (this.empresa != null) {
      this.peticion.Cliente = this.empresa.Id
      this._adminService.cargarTablaPreCliente(this.peticion).subscribe({
        next: async (response: any) => {
          this.usuarios = response
          this.filtrarEstados()
          this.filtrarUsuarios()
        }
      })
      this.vertabla = true
    } else {
      this._alertaService.mostrarAlertaConTitulo(this._translate.instant('precliente.alert.porfavorseleccione'), '', ETipoAlerta.Info);
    }


  }

  //Poder filtrar por el estado del formulario de cada usuario
  filtrarEstados() {
    const estadosUsuarios = this.usuarios.map((usuario: IRespuestaListaUsuario) => usuario.Estado);
    const estadosUnicos = Util.FiltrarDuplicadosEstados(estadosUsuarios)
    this.estadosFiltrados = this.estados.filter(e => estadosUnicos.includes(e.num))
  }

  //permite realizar la busqueda de empresas en la tabla Cliente
  listarEmpresasPreCliente(event: any) {
    this.buscarNombreEmpresa = event?.filter;
    if (this.buscarNombreEmpresa && this.buscarNombreEmpresa.length >= 3) {
      this._adminService.listarEmpresasPre(this.buscarNombreEmpresa).subscribe({
        next: (response: any) => {
          this.empresas = response;
        },
      });
    } else {
      this.empresas = [];
    }
  }

  //Nombres de los diferentes estados Disponibles para un Formulario
  nombreEstado(id: number) {
    return EEstadoPerfil[id].toLowerCase();
  }

  //Cada nombre de estado correspondera a un color
  colorEstado(id: number) {
    return EColorEstado[id];
  }

  //Obtener los diferentes estados
  obtenerEstados() {
    let estados_temp = Object.keys(EEstadoPerfil)
      .filter((key: any) => isNaN(Number(EEstadoPerfil[key])))
      .map((x) => Number(x));

    this.estados = estados_temp.map((x) => {
      return { num: x, estado: EEstadoPerfil[x].toLowerCase() };
    });
  }

  //Filtrar por nombre de usuarios 
  filtrarUsuarios() {
    this.usuariosFiltrados = this.usuarios
    if (this.usuarioSeleccionado.length > 0) {
      this.usuariosFiltrados = this.usuarios.filter(u => this.usuarioSeleccionado.some(s => s.Id === u.Id))
    }
    if (this.estadoSeleccionado.length > 0) {
      this.usuariosFiltrados = this.usuariosFiltrados.filter(u => this.estadoSeleccionado.some(s => s === u.Estado))
    }
  }

  //Cerrar sesion elimiando el token y el rol y rederigir al LoginAdmin
  CierreSesion(){
    localStorage.removeItem('user_token')
    localStorage.removeItem('rol')
    this._adminService.cerrarSesion().subscribe({
      next:(value) => {
      }
    })
    Util.redirigir(ERuta.LoginAdmin)
  }


}









import { environment } from '../../environments/environment';

export const YEAR_ACTUAL = new Date().getFullYear();
export const IMAGEN_DESTINUX = `${environment.baseArchivoAssets}/img/logo/Logo_destinux_login.svg`;
export const IMAGEN_LOGIN_FOOTER = `${environment.baseArchivoAssets}/img/logo/logo_consultia_blanco_claim.svg`;
export const PAISES = `${environment.recursos}Configuraciones/paises/idioma/countries.json`;
export const COMUNIDADES_AUTONOMAS = `${environment.recursos}Configuraciones/comunidadesautonomas.json`;
export const EXPRESIONES_REGULARES = `${environment.recursos}Configuraciones/ExpresionesRegulares.json`;
export const POLITICAS_CONDICIONES = `${environment.baseArchivoAssets}misc/Respuestas/politica_condiciones_aceptacion_idioma.json`;
export const LINK_POLITICAS_CONDICIONES = `${environment.recursos}politicas_condiciones/`;
export const PAIS_ESPANA = "es";
export const OTRO: string = "-99";
export const FORMATO_FECHA = "YYYY-MM-DD";
export const IMAGEN_LOGIN_FONDO = `${environment.baseArchivoAssets}/img/logo/fondo_pantalla_destinux.jpg`;

import { Component, OnInit } from '@angular/core';
import { LINK_POLITICAS_CONDICIONES } from 'src/app/models/constantes';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  urlPoliticasPrivacidad = LINK_POLITICAS_CONDICIONES + 'politicasPrivacidad.html'
  urlPoliticasSeguridad = LINK_POLITICAS_CONDICIONES + 'politicasSeguridad.html'
  urlCondicionesGenerales = LINK_POLITICAS_CONDICIONES + 'condicionesGenerales.html'

}

import { Injectable } from '@angular/core';
import { ServiceHttpService } from './service-http.service.';
import { ILogin, ILoginAdmin, IRespuestaLogin, IVerificarCodigo } from '../models/interfaces-apis/auth';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

/**
 * Servicio que se encarga del proceso de Login de usuarios y administradores
 */
export class AuthService
{

  /**
   * Constructor de la clase
   * @param _httpService: Servicio http base
   */
  constructor(private _httpService: ServiceHttpService) { }

  /**
   * Método que permite realizar el proceso de inicio de sesion para usuarios y administradores
   * El usuario recibira un email con un link que le permitira el acceso a la web
   * En caso de error se retorna mensaje apropiado
   * @param peticion
   * @returns IRespuestaLogin
   */
  login(peticion: ILogin): Observable<IRespuestaLogin>
  {
    return this._httpService.postApi<IRespuestaLogin>('auth/login', peticion);
  }

  /**
   * Método que permite realizar el proceso de inicio de sesion para administradores
   * @param peticion
   * @returns IRespuestaLogin
   */
  loginAdmin(peticion: ILoginAdmin): Observable<IRespuestaLogin>
  {
    return this._httpService.postApi<IRespuestaLogin>('auth/loginAdmin', peticion);
  }

  /** Método que permite verificar que el codigo puesto por el usuario es correcto y puede entrar a la ficha de cliente
  */
  verificarCodigo(peticion: IVerificarCodigo): Observable<boolean> {
    return this._httpService.postApi<any>('/auth/VerificarCodigo', peticion)
  }

}

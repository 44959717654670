import { AbstractControl } from "@angular/forms";
import { FORMATO_FECHA, YEAR_ACTUAL } from "../models/constantes";
import { EMensaje, ERuta } from "../models/enums.enum";
import { IItem } from "../models/interfaces-apis/diccionarios";
import { IFichaCliente } from "../models/interfaces-apis/ficha_cliente";
import * as dayjs from "dayjs";
import { IPais } from "../models/general/pais";

/**
 * Clase que permite definir funciones comunes
 */
export class Util {
  /**
   * Método que permite redirigir a una pagina en concreto
   * @param ruta Ruta a donde se desea enviar al usuario
   */
  static redirigir(ruta: ERuta) {
    location.href = ruta;
  }

  /**
   * Método que permite restar una cantidad de años a la fecha actual
   * @param year Cantidad de años a restar a la fecha actual
   * @returns Fecha
   */
  static obtenerFechaResta(year: number): Date {
    return new Date(YEAR_ACTUAL - year, 0, 0);
  }

  static obtenerFechaResta2(year: number, maxYear: number): Date {
    return new Date(maxYear - year, 0, 0);
  }


  static obtenerFechaDesdeControl(fecha: any){
    if(fecha !== null && fecha !== ""){
    return  dayjs(fecha).format(FORMATO_FECHA);
    }
    return null
  }
  /**
   * Método que permite crear una lista con un item por defecto
   * @returns Lista item defecto
   */
  static itemDefecto(): IItem[] {
    return [{
      Clave: null,
      Valor: EMensaje.ErrorSinResultados
    }]
  }

  static validarGUID(guid: string): boolean {
    const regex = /^\d{6}$/;
    return regex.test(guid);
  }

  static crearInstanciaFichaCliente(): IFichaCliente{
     let clienteFicha: IFichaCliente = {
      NumeroIdentificacion: "",
      IdCorporativo: 0,
      Estado: 0,
      Finalizado: false,
      Nombre: "",
      Apellidos: "",
      FechaNacimiento: "",
      Genero: null,
      PreFijo: "",
      Telefono: "",
      Newsletter: false,
      FechaAceptacionPoliticas: null,
      Poblacion: "",
      CodigoPostal: "",
      Direccion: "",
      Provincia: "",
      Pais: "",
      Correo: "",
      Documentos: [],
      TarjetasFidelizacion: [],
      Preferencias: []
    };
    return clienteFicha;
  }

  
static filtrarDuplicadosPrefijos(array: IPais[]): IPais[] {
  const groupedMap = new Map<number, string[]>();

 
  array.forEach(pais => {
      if (!groupedMap.has(pais.prefix)) {
          groupedMap.set(pais.prefix, []);
      }
      groupedMap.get(pais.prefix)?.push(pais.name);
  });
  
  

  const result: IPais[] = [];
  groupedMap.forEach((names, prefix) => {
      result.push({
          id: null,
          name: names.sort().join(', '),
          prefix: prefix,
          alpha2: null,
          alpha3: null,
          lat: null,
          long: null
      });
  });
  
  return result;
}

static FiltrarDuplicadosEstados<T>(array: T[]): T[] {
  const estadosUnicos = Array.from(new Set(array));
  return estadosUnicos;
}


}

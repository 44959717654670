import { BanderasComponent } from "../componentes/form-componentes/banderas/banderas.component"

/**
 * Enumerado que permite manejar las rutas de la aplicacion
 */
export enum ERuta
{
  Login = "./login",
  LoginAdmin = "./loginAdmin"
}
/**
 * Enumerado que permite gestionar los roles del sistema
 */

export enum ERol{
  Anonimo = 1,
  Usuario = 2,
  Admin = 3
}

/**
 * Enumerado que permite gestionar los tipos de erroes
 */
export enum ETipoErrores
{
  DatosPeticionNoValidos = 1,
  Prohibido = 2,
  NoEncontrado = 3,
  SinResultados = 4,
  Error = 5,
  Warning = 6,
}

/**
 * Enumerado que permite manejar los tipos de familia numerosa
 */
export enum ETipoFamiliaNumerosa
{
  General = 1,
  Especial = 2
}

/**
 * Enumerado que permite traducir los valores del regimen de familia numerosa
 */
export enum ETraduccionTipoFamiliaNumerosa
{
  'admin.analytics.general' = ETipoFamiliaNumerosa.General,
  'perfil.lista-documentos.tipo-familia-numerosa-especial'= ETipoFamiliaNumerosa.Especial
}

/**
 * Enumerado que permite manejar los tipos de documentos
 */
export enum ETipoDocumento
{
  NumIdentificacion = 0,
  Pasaporte = 1,
  FamiliaNumerosa = 2,
  Residente = 3
  
}

/**
 * Enumerado que permite traducir los tipos de documentos
 */
export enum ETraduccionTipoDocumento
{
  "perfil.lista-documentos.num-identificacion" = ETipoDocumento.NumIdentificacion,
  "perfil.lista-documentos.pasaporte" = ETipoDocumento.Pasaporte,
  "perfil.lista-documentos.residente" = ETipoDocumento.Residente,
  "perfil.lista-documentos.familia-numerosa" = ETipoDocumento.FamiliaNumerosa
}


/**
 * Enumerado que permite manejar los tipos de productos
 */
export enum ETipoProducto
{
  
Hotel = 1,
Aereo = 2,
RentAcar = 3,
Barco = 4,
Tren = 5,
Transfer = 9

}

/**
 * Enumerado que permite manejar los tipo de alianzas
 */
export enum ETipoAlianza
{
  Alianza = 1,
  Codigo = 2
}

/**
 * Enumerado que permite manejar los estados del perfil del cliente
 */
export enum EEstadoPerfil
{
  Inactivo = 0,
  Activo = 1,
  Guardado = 2,
  Cerrado = 3
}

/**
 * Enumerado que permite traducir los estados del perfil del cliente
 */
export enum ETraduccionEstadoPerfil
{
  "Está en preclientes pero aún no ha entrado al formulario" = EEstadoPerfil.Inactivo,
  "Ha hecho login" = EEstadoPerfil.Activo,
  "Ha hecho algún guardado" = EEstadoPerfil.Guardado,
  "Ha finalizado el formulario" = EEstadoPerfil.Cerrado
}

/**
 * Enumerado que permite manejar los genero del cliente
 */
export enum EGenero
{
  Hombre = 0,
  Mujer = 1
}

/**
 * Enumerado que permite definir los colores para los estados del perfil del cliente
 */
export enum EColorEstado
{
  "g--color-red-5" = EEstadoPerfil.Inactivo,
  "g--color-green-4" = EEstadoPerfil.Activo,
  "g--color-alternative-5" = EEstadoPerfil.Guardado,
  "g--color-purple-3" = EEstadoPerfil.Cerrado
}

/**
 * Enumerado que permite manejar los mensajes tipos de alertas
 */
export enum ETipoAlerta
{
  Success,
  Error,
  Info,
  Warning,
  Question
}

/**
 * Enumerado que permite manejar los mensajes para las alertas
 */
export enum EMensaje
{
  ComprobarPeticion = 'precliente.login.mensaje.comprobar',
  CorreoEnviadoLogin = 'precliente.login.mensaje.enviado',
  ErrorPeticion = 'admin.comun.error-peticion',
  ErrorRecurso = 'admin.comun.error-recurso',
  ErrorServidorNoDisponible = 'admin.comun.error-servidor-no-disponible',
  ErrorSinResultados = 'admin.comun.sin-resultados-titulo',
  ErrorComun = 'admin.comun.error',
  ErrorComunAtencion = 'admin.comun.atencion',
  ErrorAccesoRestringido = 'admin.password.restringido',
  BotonVolver = 'auto-reserva.hoteles.prereserva.volver',
  BotonConfirmar = 'admin.comun.confirmar',
  BotonFinalizarFormulario = 'travel-manager.asistente.finalizar',
  BotonTerminar = 'precliente.alert.finalizar-y-cerrar-sesion',
  Atencion = 'admin.comun.atencion',
  FormularioFinalizado = 'precliente.alert.acceso-denegado.formulario-finalizado',
  RolNoPermitido = 'precliente.alert.acceso-denegado.home',
  TituloFinalizarYCerrarSesion = 'precliente.alert.finalizar-y-salir',
  TituloEnhorabuena = 'precliente.alert.enhorabuena',
  VerificacionIncorrecta = 'precliente.login.titulo.verificacion-incorrecta',
  MensajeErrorVerificacion = 'precliente.login.mensaje.error-verificacion',
}

/**
 * Enumerado para los diferentes tipos de preferencia
 */
export enum ETipoPreferencia{
  Alergia = 2,
  Fumador = 11,
  Alimentacion = 10,
  Movilidad = 3,
  RegimenesHotel = 4,
  ImprescindiblesHotel = 5,
  PreferenciaAsientosVuelo = 6,
  PreferenciaAsientosTren = 7,
  SentidoMarchaTren = 8,
  CompaniasAreas = 12,
  CadenasHoteleras = 13,
  CompaniasCoche = 14,
  CategoriaVehiculo = 15,
  ObservacionAereo = 16,
  ObservacionTren = 17,
  ObservacionRentACar = 18,
  ObservacionTransfer = 19,
  ObservacionBarco = 20,
  PreferenciaMesaTren = 21  
}

/**
 * Enumerado de diferentes acciones en el formulario
 */
export enum EAccionFormulario{
  Guardar = 1,
  Continuar = 2,
  Finalizar = 3
}

/**
 * Enumerado para los diferentes pasos del Stepper
 */
export enum EPasosStepper{
  Ficha = 0,
  Perfil = 1,
  Preferencias = 2
}

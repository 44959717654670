import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ITarjetaFidelizacion } from 'src/app/models/interfaces-apis/ficha_cliente';
import { GeneralService } from 'src/app/servicios/general.service';
import { validarFechaCaducidad } from 'src/app/shared/validaciones';
import { Util } from 'src/app/shared/util';
import { IAlianza, ICompania, IItem } from 'src/app/models/interfaces-apis/diccionarios';
import { ETipoAlianza, ETipoProducto } from 'src/app/models/enums.enum';
import { ItemConverter } from 'src/app/shared/item-converter';
import { OTRO } from 'src/app/models/constantes';
import { AlertaService } from 'src/app/shared/alerta.service';
import { IExpresion } from 'src/app/models/General/expresion-regular';
import { TranslateService } from '@ngx-translate/core';
import { TooltipComponent } from '@angular/material/tooltip';

@Component({
  selector: 'app-tarjeta-fidelizacion',
  templateUrl: './tarjeta-fidelizacion.component.html',
  styleUrls: ['./tarjeta-fidelizacion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TarjetaFidelizacionComponent implements OnInit {

  @ViewChild('table') table: MatTable<any>;

  @ViewChild('updateButton', { static: true }) updateButton: ElementRef<HTMLButtonElement>;


  form: FormGroup;


  /**
 * Fecha maxima a seleccionar como nacimiento
 */
  maximaFechaNacimientoPermitida: Date = Util.obtenerFechaResta2(0, 2040);

  /**
   * Fecha minima a seleccionar como nacimiento
   */
  minimaFechaNacimientoPermitida: Date = Util.obtenerFechaResta2(82, 2024);


  @Input() TiposProductos: IItem[];
  @Input() Alianzas: IAlianza[];
  @Input() AereoLineas: IItem[];
  @Input() Companias: ICompania[];
  @Input() TarjetaFidelizacion: ITarjetaFidelizacion[]
  @Input() lectura: boolean = false


  OTRO = OTRO;

  ETipoProducto = ETipoProducto;

  ETipoAlianza = ETipoAlianza;

  listaAlianzas: IItem[] = [];
  alianzasConvertidos: boolean = false;

  diccionariosCompanias: { [Clave: number]: any } = {}

  listaMiembrosAlianza: IItem[] = [];

  companiasConvertidos: boolean = false;

  aereoLineasConvertidos: boolean = false;

  @Input() expresionesRegulares: IExpresion;


  TipoCompania: IItem[] = [
    {
      Clave: "1",
      Valor: this._translate.instant('perfil.perfil-usuario.tarjeta-fidelizacion.tipo-alianza')
    },
    {
      Clave: "2",
      Valor: this._translate.instant('perfil.perfil-usuario.tarjeta-fidelizacion.tipo-codigo')

    }]


  fidelizacionForms: FormGroup[] = [];
  columnas: string[] = ['TipoProducto', 'Compania', 'Numeracion', 'FechaCaducidad', 'Notas', 'Acciones', 'OtroCodigo', 'NombreAlianza', 'CodigoAlianza', 'CodigoAlianzaAereo', 'CodigoAereo'];
  dataSource: MatTableDataSource<AbstractControl>
  constructor(private fb: FormBuilder, private _generalService: GeneralService, private _alertaService: AlertaService,private _translate: TranslateService) {
    this.form = this.fb.group({
      forms: this.fb.array([]),
    });
   }

  async ngOnInit(): Promise<void> {
    setTimeout(() => {
      this.updateButton.nativeElement.click();
    }, 2000);

  }

  // Crea un formulario reactivo para cada tarjeta en la lista
  actualizarTarjeta(TarjetasFidelizacion: ITarjetaFidelizacion[]) {
    this.fidelizacionForms = []
   
      this.TarjetaFidelizacion = TarjetasFidelizacion
      this.TarjetaFidelizacion.forEach(fid => {
        let valorOtro = null;
        let valorOtroAereo = null;
        let valorCompania = fid.Compania;
        let valorCompaniaAerea = fid.Compania;
        let TipoCompaniaAerea = "2"
        let CodigoAlianza = null;

        if (fid.TipoProducto !== ETipoProducto.Aereo) {
          let CompaniasExistentes = this.Companias.find(c => c.Codigo == valorCompania && c.TipoProducto == fid.TipoProducto)
          if (CompaniasExistentes == null) {
            valorOtro = fid.Compania
            valorCompania = OTRO
          }
        } else {
          if (fid.Alianza !== null) {
            TipoCompaniaAerea = "1"
            CodigoAlianza = fid.Compania
            this.seleccionarOpcionMiembroAlianza(fid.Alianza.toString())

          } else {
            let existe1 = this.AereoLineas.find(a => a.Clave == fid.Compania)
            if (existe1 == null) {
              valorOtroAereo = fid.Compania
              valorCompaniaAerea = OTRO
            }
          }
        }

        const form = this.fb.group({
          TipoProducto: [fid.TipoProducto.toString(), Validators.required],
          Compania: [fid.Compania, [Validators.maxLength(20)]],
          Numeracion: [fid.Numeracion, [Validators.required, Validators.pattern(this.expresionesRegulares?.numerosYletras), Validators.maxLength(20)]],
          FechaCaducidad: [fid?.FechaCaducidad, [validarFechaCaducidad]],
          Notas: [fid.Notas, Validators.maxLength(100)],
          Alianza: [fid.Alianza?.toString()],
          Proveedor: [fid.Proveedor],
          OtroCodigo: [valorOtro, Validators.maxLength(20)],
          CompaniasMenosAereo: [valorCompania],
          CompaniaAerea: [TipoCompaniaAerea],
          NombreAlianza: [fid.Alianza],
          CodigoAlianzaAereo: [valorCompaniaAerea],
          CodigoAlianza: [CodigoAlianza],
          CodigoAereo: [valorOtroAereo, Validators.maxLength(3)]

        });
        this.fidelizacionForms.push(form);
      });
      this.form = this.fb.group({
        forms: this.fb.array(this.fidelizacionForms),
      });
      this.dataSource = new MatTableDataSource(
        this.formsControlArray.controls);
    
  }

  //Obtener cada valor de la Tarjeta
  obtenerTarjetas(): ITarjetaFidelizacion[] {
    const tarjetas: ITarjetaFidelizacion[] = this.formsControlArray.controls.map(form => {
      let TipoProducto = form.get('TipoProducto').value
      let Alianzas = form.get('NombreAlianza').value
      let FechaCaducidad = Util.obtenerFechaDesdeControl(form.get('FechaCaducidad').value);
      let CompaniaProveedor = this.obtenerCompania(TipoProducto, form)
      const tarjeta: ITarjetaFidelizacion = {
        TipoProducto: TipoProducto,
        Compania: CompaniaProveedor[0],
        Numeracion: form.get('Numeracion').value,
        FechaCaducidad: FechaCaducidad,
        Notas: form.get('Notas').value,
        Alianza: this.obtenerAlianza(TipoProducto, Alianzas),
        Proveedor: CompaniaProveedor[1],
      }
      return tarjeta;
    });
    return tarjetas;
  }

  //Validamos que no haya algún campo inválido en nuestro formulario
  validarFormulariosTarjetas() {
    return !this.formsControlArray.controls.some(form => form.invalid)
  }

  //Obtener valor de las compañias
  obtenerCompania(TipoProducto, form) {
    let OtroCompania = null;
    let proveedor = null
    if (TipoProducto !== ETipoProducto.Aereo.toString()) {
      OtroCompania = form.get('CompaniasMenosAereo').value
      if (OtroCompania == OTRO) {
        OtroCompania = form.get('OtroCodigo')?.value
      } else {
        proveedor = this.Companias.find(c => c.Codigo == OtroCompania && c.TipoProducto == TipoProducto)?.Proveedor
      }
    }
    if (TipoProducto == ETipoProducto.Aereo.toString()) {
      let TipoCompania = form.get('CompaniaAerea').value
      if (TipoCompania == ETipoAlianza.Codigo.toString()) {
        OtroCompania = form.get('CodigoAlianzaAereo').value

        if (OtroCompania == OTRO) {
          OtroCompania = form.get('CodigoAereo')?.value
        }
      } else {
        OtroCompania = form.get('CodigoAlianza').value
      }
    }
    return [OtroCompania, proveedor];

  }

  //Obtener valor de la alianza
  obtenerAlianza(TipoProducto, form) {
    if (TipoProducto == ETipoProducto.Aereo.toString()) {
      return form;
    }
    return null;
  }

  //Eliminamos la tarjeta y actualizamos la tabla
  eliminarTarjetaFidelizacion(index: number) {
    this.formsControlArray.removeAt(index);
    this.table.renderRows();
  }

  //Mostramos alerta y llamamos a la funcion para poder eliminar la tarjeta
  cancelarEdicion(index: number) {
    this._alertaService.eliminarTarjetaFidelizacion(() => this.eliminarTarjetaFidelizacion(index));
  }

  //Crea una nueva Tarjeta de Fidelización
  crearFila() {
    const filas = this.formsControlArray;
    filas.push(
      this.fb.group({
        TipoProducto: ["", Validators.required],
        Compania: ["",[Validators.maxLength(20)]],
        Numeracion: ["", [Validators.required, Validators.pattern(this.expresionesRegulares?.numerosYletras), Validators.maxLength(20)]],
        FechaCaducidad: ["", validarFechaCaducidad],
        Notas: ["", Validators.maxLength(100)],
        Alianza: [""],
        Proveedor: [""],
        OtroCodigo: ['', Validators.maxLength(20)],
        CompaniasMenosAereo: [''],
        CompaniaAerea: [''],
        NombreAlianza: [''],
        CodigoAlianzaAereo: [''],
        CodigoAlianza: [''],
        CodigoAereo: ['', Validators.maxLength(3)]

      })
    );
  }

  //Llamamos al crear para una nueva tarjeta y actualizamos la tabla
  crearFidelizacion() {
    this.crearFila();
    this.table.renderRows();
  }

  //Acceder a un formulario y tratarlo como FormArray
  get formsControlArray() {
    return this.form.get('forms') as FormArray;
  }

  seleccionarOpcionMiembroAlianza(claveAlianza: string) {
    let alianza = this.Alianzas.find(A => A.Id == parseInt(claveAlianza))
    this.listaMiembrosAlianza = ItemConverter.transformarArrayToItem(alianza.Miembros);
  }

  //Lista con los nombres de las alianzas
  convertirAlianzas() {
    if (this.Alianzas && this.alianzasConvertidos === false) {
      this.listaAlianzas = ItemConverter.transformarObjetoToItem(this.Alianzas, 'Id', 'Nombre');
      this.alianzasConvertidos = true;
    }
    return this.listaAlianzas;

  }

  //Lista con los nombres de compañias
  convertirCompanias(TipoProducto: any) {
    if (TipoProducto in this.diccionariosCompanias) {
      return this.diccionariosCompanias[TipoProducto]
    }
    let listaCompanias = []

    if (this.Companias && TipoProducto !== "") {
      let companiasProducto = this.Companias.filter(c => c.TipoProducto == TipoProducto)
      listaCompanias = ItemConverter.transformarObjetoToItem(companiasProducto, 'Codigo', 'Nombre');
      this.companiasConvertidos = true;
      let Clave: string = OTRO;
      let Valor: string = this._translate.instant('precliente.otro');

      listaCompanias.unshift({ Clave, Valor });
      this.diccionariosCompanias[TipoProducto] = listaCompanias
    }

    return listaCompanias;
  }

  //Lista con los nombres de las aerolineas
  ListaAereoLineas() {
    if (this.AereoLineas && this.aereoLineasConvertidos === false) {

      let Clave = OTRO;
      let Valor = this._translate.instant('precliente.otro');

      this.AereoLineas.unshift({ Clave, Valor });
      this.aereoLineasConvertidos = true;
    }


    return this.AereoLineas;
  }

  //Actualizar valores a null en determinados casos
  seleccionTipoCodigo(valor, form) {
    if (valor == 1) {
      form.patchValue({
        NombreAlianza: null,
        CodigoAlianza: null
      })
    } else {
      form.patchValue({
        OtroCodigo: null,
        CodigoAlianzaAereo: null
      })
    }
    this.validarCamposAlianza(form, valor)
  }

  //Actualizar valores a null en determinados casos
  seleccionTipoProducto(valor, form) {
    if (valor == 2) {
      form.patchValue({
        NombreAlianza: null,
        CodigoAlianza: null,
        CompaniasMenosAereo: null

      })
    } else {
      form.patchValue({
        CompaniaAerea: null,
        NombreAlianza: null,
        CodigoAlianzaAereo: null,
      })
    }
    this.validarCompania(form)

  }

  seleccionTipoCompanyia(form){
    this.validarCompania(form)
  }

  //Vaciar el campo de Fecha en el calendario
  vaciarFecha(form: any) {
    form.get('FechaCaducidad').setValue(null);
  }

  actualizarTabla() {}


  //Función para poder cambiar las validaciones de Compañia dependiendo de algunas condiciones
  validarCompania(form: any) {
  const TipoProducto = form.get('TipoProducto').value;
  const TipoCompaniaMenosAereoControl = form.get('CompaniasMenosAereo');
  const TipoCompaniaAereoControl = form.get('CompaniaAerea');
  const OtroCodigo = form.get('OtroCodigo');
  const NombreAlianza = form.get('NombreAlianza');
  const CodigoAlianzaAereo = form.get('CodigoAlianzaAereo');
  const CodigoAlianza = form.get('CodigoAlianza');

  if ( TipoProducto == ETipoProducto.Aereo) {
    OtroCodigo.setValidators([]);
    TipoCompaniaMenosAereoControl.setValidators([]);
    TipoCompaniaAereoControl.setValidators([Validators.required]);
    TipoCompaniaAereoControl.updateValueAndValidity();
    OtroCodigo.updateValueAndValidity();
    TipoCompaniaMenosAereoControl.updateValueAndValidity();

  }else if( TipoProducto !== ETipoProducto.Aereo){
    NombreAlianza.setValidators([]);
    TipoCompaniaAereoControl.setValidators([]);
    CodigoAlianzaAereo.setValidators([]);
    CodigoAlianza.setValidators([]);
    NombreAlianza.updateValueAndValidity();
    TipoCompaniaAereoControl.updateValueAndValidity();
    CodigoAlianzaAereo.updateValueAndValidity();
    CodigoAlianza.updateValueAndValidity();
    

  }else if( TipoProducto !== ETipoProducto.Aereo && TipoCompaniaMenosAereoControl !== OTRO){
    OtroCodigo.setValidators([]);
    TipoCompaniaMenosAereoControl.setValidators([Validators.required]);
    TipoCompaniaMenosAereoControl.updateValueAndValidity();
    OtroCodigo.updateValueAndValidity();

  }else if( TipoProducto !== ETipoProducto.Aereo && TipoCompaniaMenosAereoControl == OTRO ){
    OtroCodigo.setValidators([Validators.required]);
    OtroCodigo.updateValueAndValidity();
  }
  }

  //Función para poder cambiar las validaciones de Alianza dependiendo de algunas condiciones
  validarCamposAlianza(form: any, tipo: any){
    const NombreAlianza = form.get('NombreAlianza');
    const CodigoAlianza = form.get('CodigoAlianza');
    const CodigoAlianzaAereo = form.get('CodigoAlianzaAereo');
    const CodigoAereo = form.get('CodigoAereo');

    if(tipo == 1){
      NombreAlianza.setValidators([Validators.required]);
      CodigoAlianza.setValidators([Validators.required]);
      NombreAlianza.updateValueAndValidity();
      CodigoAlianza.updateValueAndValidity();
      
    }else{
      NombreAlianza.setValidators([]);
      CodigoAlianza.setValidators([]);
      NombreAlianza.updateValueAndValidity();
      CodigoAlianza.updateValueAndValidity();
      
    }
    if(CodigoAlianzaAereo == OTRO){
      CodigoAereo.setValidators([Validators.required,Validators.maxLength(3)]);
      CodigoAereo.updateValueAndValidity();
    }else{
      CodigoAereo.setValidators([]);
      CodigoAereo.updateValueAndValidity();
    }
    
  }

  //Función para cambiar validaciones de CodigoAereo dependiendo de algunas condiciones
  seleccionCodigoAlianzaAereo(valor: any, form: any){
    const CodigoAereo = form.get('CodigoAereo');
    
    if(valor == OTRO){
      CodigoAereo.setValidators([Validators.required, Validators.maxLength(3)]);
      CodigoAereo.updateValueAndValidity();
    }else{
      CodigoAereo.setValidators([]);
      CodigoAereo.updateValueAndValidity();
    }
  }

 


}



import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ERuta, ETipoAlerta } from 'src/app/models/enums.enum';
import { IPoliticasCondiciones } from 'src/app/models/General/politica-condiciones';
import { IFichaCliente } from 'src/app/models/interfaces-apis/ficha_cliente';
import { GeneralService } from 'src/app/servicios/general.service';
import { AlertaService } from 'src/app/shared/alerta.service';
import { Util } from 'src/app/shared/util';

@Component({
  selector: 'app-politicas-condiciones',
  templateUrl: './politicas-condiciones.component.html',
  styleUrls: ['./politicas-condiciones.component.scss']
})
export class PoliticasCondicionesComponent implements OnInit {

  /**
  * Lista de expresiones regulares
  */
  politicasCondiciones: IPoliticasCondiciones;

  @Input() lectura: boolean = false

  @Input() dentroModal: boolean = false;

  @Input() mostrarPoliticasCondiciones: boolean = true

  @Input() mostrar: boolean = true

  FechaAceptacionPoliticas: Date

  @Input() clienteFicha: IFichaCliente;

  constructor(private _alertaService: AlertaService, private _http: HttpClient, private _generalService: GeneralService) { }

  async ngOnInit() {
   this.politicasCondiciones = await this._generalService.cargarPoliticasYCondiciones();
  }
  noAceptoCondiciones(){
    this._alertaService.politicasYCondiciones(this.rederigir);
  }

   //Función para rederigir al Login
   rederigir() {
    Util.redirigir(ERuta.Login)
  }

  aceptoPoliticas(){
    this.clienteFicha.FechaAceptacionPoliticas = new Date()
  }
}

import { Component, Inject, Renderer2 } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { IMAGEN_LOGIN_FONDO, IMAGEN_LOGIN_FOOTER, LINK_POLITICAS_CONDICIONES } from 'src/app/models/constantes';
import { AuthService } from 'src/app/servicios/auth.service';
import { ILogin, IVerificarCodigo } from 'src/app/models/interfaces-apis/auth';
import { EMensaje, ETipoAlerta } from 'src/app/models/enums.enum';
import { AlertaService } from 'src/app/shared/alerta.service';
import { DOCUMENT } from '@angular/common';
import { UserService } from 'src/app/servicios/user.service';
import { Router } from '@angular/router';
import { Util } from 'src/app/shared/util';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

/**
 * Componente login que permite realizar el proceso de inicio de sesion de los usuarios
 */
export class LoginComponent {

  urlPoliticasPrivacidad = LINK_POLITICAS_CONDICIONES + 'politicasPrivacidad.html'
  urlPoliticasSeguridad = LINK_POLITICAS_CONDICIONES + 'politicasSeguridad.html'
  urlCondicionesGenerales = LINK_POLITICAS_CONDICIONES + 'condicionesGenerales.html'

  //#region VARIABLES

  /**
   * Formulario
   */
  form: any;

  /**
   * Imagen para el footer
   */
  imagenFooter = IMAGEN_LOGIN_FOOTER;

  /**
   * Peticion que contiene la estructura necesaria para llamar a la api
   */
  #peticion: ILogin =
    {
      Correo: null
    };

  usuarioIdentificado: boolean = false

  //#endregion


  /**
   * Constructor del componente
   * @param _authService: Servicio que permite realizar las llamadas a la api
   * @param _formBuilder: Manejador del formulario
   * @param _alertaService: Servicio de alerta
   */
  constructor(private renderer: Renderer2, @Inject(DOCUMENT) private document: Document,
    private _authService: AuthService,
    private readonly _formBuilder: FormBuilder,
    private _alertaService: AlertaService,
    private router: Router
  ) {
    this.inicializaFormulario()
  }

  //Hacemos que en el Login haya un cambio en el body y asi mostrar la imagen de fondo de pantalla
  ngOnInit() {
    this.renderer.setStyle(this.document.body, 'background-image', `url(${IMAGEN_LOGIN_FONDO})`);

  }



  //#region METODO PUBLICOS

  /**
   * Método que permite enviar el formulario para realizar el inicio de sesion
   */
  enviarFormulario() {
    if (this.form.valid) {
      // Se establecen los datos de la peticion
      this.establecerPeticion();
      // Se realiza la llamada al login
      this.login(this.#peticion);
    }
    else {
      // Se informa al usuario que los datos de la peticion no son validos
      this._alertaService.mostrarAlerta(EMensaje.ComprobarPeticion, ETipoAlerta.Info)
    };
  }

  //#endregion

  //#region METODOS PRIVADOS

  /**
   * Método que permite inicializar el formulario
   */
  private inicializaFormulario() {
    this.form = this._formBuilder.group({
      correo: ['', [Validators.required, Validators.email]],
      codigo: ['']
    });
  }

  /**
   * Método que realiza el login
   * @param peticion: Peticion necesaria para hacer login
   */
  private login(peticion: ILogin) {
    this._authService.login(peticion).subscribe({
      next: (rol: any) => {
        localStorage.setItem("rol", rol);
        this.verificarRol()
        this._alertaService.mostrarAlerta(EMensaje.CorreoEnviadoLogin, ETipoAlerta.Success);
      },
    });
  }

  //Verificamos que el rol del usuario es el 2
  verificarRol() {
    let usuario = localStorage.getItem('rol')
    let valorUsuario = usuario ? parseInt(usuario, 10) : null
    if (valorUsuario === 2) {
      this.usuarioIdentificado = true
    } else {
      this.usuarioIdentificado = false
    }
  }

  /**
   * Método para entrar en la ficha
   * @param codigo: Peticion necesaria para acceder a la ficha
   */
  verificarCodigo() {
    let codigo = this.form.get('codigo')?.value
    let peticion: IVerificarCodigo =
    {
      Codigo: codigo
    };

    this._authService.verificarCodigo(peticion).subscribe({
      next: (codigoValido: boolean) => {
        if (codigoValido) {
          localStorage.setItem('user_token', codigo); //TODO Establecer token en user_token
          location.href = '/ficha-cliente/' + codigo
          
        } else {
          this._alertaService.mostrarAlertaConTitulo(EMensaje.VerificacionIncorrecta, EMensaje.MensajeErrorVerificacion, ETipoAlerta.Error)

        }
      },
    });
  }

  /**
   * Método que permite crear la petición
   */
  private establecerPeticion() {
    this.#peticion.Correo = this.form.value.correo;
  }

  //#endregion

}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { EMensaje, ERol, ERuta, ETipoAlerta } from 'src/app/models/enums.enum';
import { UserService } from 'src/app/servicios/user.service';
import { AlertaService } from 'src/app/shared/alerta.service';
import { Util } from 'src/app/shared/util';

@Injectable({
  providedIn: 'root'
})
export class GuardFichaGuard implements CanActivate {

  token: string
  rol: number

  constructor(private _userService: UserService, private _alertaService: AlertaService, private router : Router) {
    
    let ruta = window.location.pathname;
    let partes = ruta.split("/ficha-cliente/");
     this.token = partes[1];
    this.rol = parseInt(localStorage.getItem('rol'));
  
  }

  //Guard en la que verificamos que el token sigue la estructura correcta, el rol es correcto y es valido para poder acceder la ruta de ficha 
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
      if (!Util.validarGUID(this.token) || this.rol != ERol.Usuario || !this.isValid() ) {
        this._alertaService.mostrarAlerta(EMensaje.RolNoPermitido, ETipoAlerta.Error, ERuta.Login)
        return false
      }
      return true
  }

  //Validamos que el token sea igual al token que tenemos en el localStorage
  isValid(){
    let localStorageCodigo = localStorage.getItem('user_token');
    return this.token === localStorageCodigo
  }
  

  
}


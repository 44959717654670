import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ETipoPreferencia } from 'src/app/models/enums.enum';
import { GrupoPreferenciaComponent } from '../../../form-componentes/grupo-preferencia/grupo-preferencia.component';
import { IFichaCliente, IPreferenciaCliente } from 'src/app/models/interfaces-apis/ficha_cliente';
import { IPreferencia } from 'src/app/models/interfaces-apis/diccionarios';
import { preferencias } from 'src/app/models/General/preferencias';

@Component({
  selector: 'app-usuario-perfil',
  templateUrl: './usuario-perfil.component.html',
  styleUrls: ['./usuario-perfil.component.scss']
})
export class UsuarioPerfilComponent extends preferencias implements OnInit {


  @ViewChild('Grupo_Preferencia_Movilidad') Grupo_Preferencia_Movilidad: GrupoPreferenciaComponent;
  @ViewChild('Grupo_Preferencia_Fumador') Grupo_Preferencia_Fumador: GrupoPreferenciaComponent;


  @Input() preferencias: IPreferencia[];

  @Input() clienteFicha: IFichaCliente;

  TipoPreferencia = ETipoPreferencia;

  constructor() {
    super();
  }

  ngOnInit(): void {

  }


  //Obtener la lista de las preferencias guardadas
  obtenerPreferenciasGuardadas(): [IPreferenciaCliente[]] {

    let ListaPreferencias: IPreferenciaCliente[] = []

    let tuplamovilidad = this.Grupo_Preferencia_Movilidad.obtenerPreferencias()
    let preferenciaMovilidad: IPreferenciaCliente = {
      Tipo: ETipoPreferencia.Movilidad,
      Preferencias: tuplamovilidad[0],
      InfoEspecifica: tuplamovilidad[1]
    }
    ListaPreferencias.push(preferenciaMovilidad)

    let tuplafumador = this.Grupo_Preferencia_Fumador.obtenerPreferencias()
    let preferenciaFumador: IPreferenciaCliente = {
      Tipo: ETipoPreferencia.Fumador,
      Preferencias: tuplafumador[0],
      InfoEspecifica: tuplafumador[1]
    }
    ListaPreferencias.push(preferenciaFumador);

    return [ListaPreferencias];

  }

  //Actualizar las preferencias del Perfil
  actualizar() {
    let preferencias = this.clienteFicha.Preferencias
    let preferenciaMovilidad: IPreferenciaCliente = this.obtenerPreferenciasCliente(preferencias, ETipoPreferencia.Movilidad)
    let preferenciaFumador: IPreferenciaCliente = this.obtenerPreferenciasCliente(preferencias, ETipoPreferencia.Fumador)

    this.Grupo_Preferencia_Movilidad.actualizar(preferenciaMovilidad?.Preferencias, preferenciaMovilidad?.InfoEspecifica);
    this.Grupo_Preferencia_Fumador.actualizar(preferenciaFumador?.Preferencias);
    
  }

  //Validar las preferencias del Perfil
  validarPreferencias() {
    let estadoMovilidad = this.Grupo_Preferencia_Movilidad.validarPreferencias()
    let estadoFumador = this.Grupo_Preferencia_Fumador.validarPreferencias()

    if (estadoMovilidad && estadoFumador) {
      return true;
    }
    return false;
  }



}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { EMensaje, ERol, ERuta, ETipoAlerta } from 'src/app/models/enums.enum';
import { UserService } from 'src/app/servicios/user.service';
import { AlertaService } from 'src/app/shared/alerta.service';
import { Util } from 'src/app/shared/util';

@Injectable({
  providedIn: 'root'
})
export class GuardAdminGuard implements CanActivate {

  token: string
  rol: number

  constructor(private _userService: UserService, private _alertaService: AlertaService) {
    this.token = localStorage.getItem('user_token'); //TODO Establecer token en user_token
    this.rol = parseInt(localStorage.getItem('rol'));

  }

  //Guard en la que verificamos que el token sigue la estructura correcta, y el rol de admin es correcto para poder acceder la ruta de ficha 
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!Util.validarGUID(this.token) || this.rol != ERol.Admin) {
      this._alertaService.mostrarAlerta(EMensaje.RolNoPermitido, ETipoAlerta.Error, ERuta.LoginAdmin)
      return false
    }
    return true;
  }


}

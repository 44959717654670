import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as dayjs from 'dayjs';
import { FichaUsuarioComponent } from 'src/app/componentes/vista-ficha-usuario/formulario/ficha-usuario/ficha-usuario.component';
import { IExpresion } from 'src/app/models/General/expresion-regular';
import { EEstadoPerfil, EMensaje, ERol, ERuta, ETipoAlerta } from 'src/app/models/enums.enum';
import { IPais } from 'src/app/models/general/pais';
import { IDiccionarios, IItem } from 'src/app/models/interfaces-apis/diccionarios';
import { IFichaCliente, IRespuestaFichaCliente } from 'src/app/models/interfaces-apis/ficha_cliente';
import { GeneralService } from 'src/app/servicios/general.service';
import { UserService } from 'src/app/servicios/user.service';
import { AlertaService } from 'src/app/shared/alerta.service';
import { Util } from 'src/app/shared/util';
import { ItemConverter } from 'src/app/shared/item-converter';
import { ICodigoComunidad } from 'src/app/models/General/comunidad-autonoma';
import { TranslateService } from '@ngx-translate/core';
import { IPoliticasCondiciones } from 'src/app/models/General/politica-condiciones';
@Component({
  selector: 'app-cliente-ficha',
  templateUrl: './cliente-ficha.component.html',
  styleUrls: ['./cliente-ficha.component.scss']
})

export class ClienteFichaComponent implements OnInit {

  @ViewChild(FichaUsuarioComponent) fichaUsuario: FichaUsuarioComponent;


  @Input() lectura: boolean = false
  
  /**
   * Lista de diccionarios
   */
  diccionarios: IDiccionarios[] = []

  /**
   * Respuesta carga cliente
   */
  respuestaCargaCliente: IRespuestaFichaCliente = null;

  clienteFicha: IFichaCliente = Util.crearInstanciaFichaCliente();

  /**
  * Lista de expresiones regulares
  */
  expresionesRegulares: IExpresion;

    /**
   * Lista de paises
   */
    paises: IPais[] = [];

  /**
    * Lista de paises en la estructura de item para ser utilizada en el selector
    */
   listaPaises: IItem[] = [];
 
   /**
    * Lista de prefijos en la estructura de item para ser utilizada en el selector
    */
   listaPrefijos: IItem[] = [];

   /**
   * Lista de comunidades
   */
  comunidades: ICodigoComunidad[] = [];

  /**
   * Lista de comunidades en la estructura de item para ser utilizada en el selector
   */
  listaComunidades: IItem[] = [];

  nombrePerfil: string

  ramasPerfil: any
 

  constructor(private _userService: UserService, private _alertaService: AlertaService,  private _generalService: GeneralService, private _translate: TranslateService) {
    this._alertaService.mostrarCargando(this._translate.instant('precliente.alerta-cargando-titulo'), this._translate.instant('precliente.alerta-cargando-texto') )
    setTimeout(() => {
    this.cargaCliente();
    
  }, 2000);
  }

  /**
  *Inicializamos con la carga de diccionarios, expresiones regulares y la carga de cliente
  con un timeout para que en ese punto ya este todo cargado, y por último todas las demás listas encesarias
  */
  async ngOnInit() {
    this.cargaDiccionario();
    this.expresionesRegulares = await this._generalService.cargarExpresionRegulares();

    
    this.paises = await this._generalService.cargarPaises();
    let prefijos = Util.filtrarDuplicadosPrefijos(this.paises)
    this.listaPaises = ItemConverter.transformarObjetoToItem(this.paises, 'alpha2', 'name');
    this.listaPrefijos = ItemConverter.transformarObjetoToItem(prefijos, 'prefix', 'prefix');
    this.comunidades = await this._generalService.cargarComunidades();
    this.listaComunidades = ItemConverter.transformarObjetoToItem(this.comunidades, 'Codigo', 'Nombre');
  }

  //La carga del diccionario y llamada a la funcion para ahcer las traducciones
  cargaDiccionario() {
    this._userService.cargarDiccionarios().subscribe({
      next: (data: any) => {
        this.diccionarios = data;
        this.diccionarios["Generos"] = this.traducirGenero(this.diccionarios["Generos"])
        this.diccionarios["TipoDocumentos"] = this.traducirTipoDocumento(this.diccionarios["TipoDocumentos"])
        this.diccionarios["TipoFamiliaNumerosa"] = this.traducirTipoFamiliaNumerosa(this.diccionarios["TipoFamiliaNumerosa"])
        this.diccionarios["TipoProductos"] = this.traducirTipoProducto(this.diccionarios["TipoProductos"])
      },
    });
  }

  //Cargar los datos del cliente
  cargaCliente() {
    console.log(this.clienteFicha)
    this._userService.cargarCliente().subscribe({
      next: (data: any) => {
        this.respuestaCargaCliente = data;
        if (data.PreCliente.Estado == EEstadoPerfil.Cerrado) {
          this._alertaService.mostrarAlerta(EMensaje.FormularioFinalizado, ETipoAlerta.Info, ERuta.Login)

        }
        this.ramasPerfil = data.DatosCliente.Ramas
        this.establecerDatosBasicosCliente();
        this.fichaUsuario.actualizarFormulario();
      },
    });
  }

  //Establecer los datos basicos del cliente que ya vienen definidos
  establecerDatosBasicosCliente() {
    if (this.respuestaCargaCliente.PreCliente != null) {
      this.clienteFicha.Nombre = this.respuestaCargaCliente.PreCliente.Nombre;
      this.clienteFicha.Apellidos = this.respuestaCargaCliente.PreCliente.Apellidos;
      this.clienteFicha.Genero = this.respuestaCargaCliente.PreCliente.Genero
      this.clienteFicha.FechaNacimiento = this.fechaFormat(this.respuestaCargaCliente.PreCliente.FechaNacimiento);
      this.clienteFicha.PreFijo = this.respuestaCargaCliente.PreCliente.PreFijo;
      this.clienteFicha.Telefono = this.respuestaCargaCliente.PreCliente.Telefono;
      this.clienteFicha.Newsletter = this.respuestaCargaCliente.PreCliente.Newsletter;
      this.clienteFicha.FechaAceptacionPoliticas = this.respuestaCargaCliente.PreCliente.FechaAceptacionPoliticas
      this.clienteFicha.Poblacion = this.respuestaCargaCliente.PreCliente.Poblacion;
      this.clienteFicha.CodigoPostal = this.respuestaCargaCliente.PreCliente.CodigoPostal;
      this.clienteFicha.Direccion = this.respuestaCargaCliente.PreCliente.Direccion;
      this.clienteFicha.Provincia = this.respuestaCargaCliente.PreCliente.Provincia;
      this.clienteFicha.Pais = this.respuestaCargaCliente.PreCliente.Pais;
      this.clienteFicha.Correo = this.respuestaCargaCliente.PreCliente.Correo;
      this.clienteFicha.Documentos = this.respuestaCargaCliente.PreCliente.Documentos;
      this.clienteFicha.TarjetasFidelizacion = this.respuestaCargaCliente.PreCliente.TarjetasFidelizacion;
      this.clienteFicha.Preferencias = this.respuestaCargaCliente.PreCliente.Preferencias;
    } else {
      this.clienteFicha.Nombre = this.respuestaCargaCliente.DatosCliente.Nombre;
      this.clienteFicha.Apellidos = this.respuestaCargaCliente.DatosCliente.Apellidos;
      this.clienteFicha.PreFijo = this.respuestaCargaCliente.DatosCliente.PreFijo;
      this.clienteFicha.Telefono = this.respuestaCargaCliente.DatosCliente.Telefono;
      this.clienteFicha.Correo = this.respuestaCargaCliente.DatosCliente.Email;
      this.clienteFicha.Pais = this.respuestaCargaCliente.DatosCliente.Pais;
    };
    this.nombrePerfil = this.clienteFicha?.Nombre + " " + this.clienteFicha?.Apellidos
    console.log(this.clienteFicha)
  }

  //El formato que vamos a establecer para mostrar la fecha
  fechaFormat(fecha: string) {
    return dayjs(fecha).format("YYYY-MM-DDTHH:mm:ss");
  };

  //Traduccion para varios idiomas de las opciones de Genero
  traducirGenero(diccionarios: any){
    for(let diccionario of diccionarios){
      if(diccionario["Clave"] == '0'){
       diccionario["Valor"] = this._translate.instant('perfil.editar-perfil.hombre')
      }
      if(diccionario["Clave"] == '1'){
        diccionario["Valor"] = this._translate.instant('perfil.editar-perfil.mujer')
       }
    }
    return diccionarios
  }

  //Traduccion para varios idiomas de las opciones de Tipo de Documento
  traducirTipoDocumento(diccionarios: any){
    for(let diccionario of diccionarios){
      if(diccionario["Clave"] == '0'){
        diccionario["Valor"] = this._translate.instant('perfil.lista-documentos.num-identificacion')
      }
      if(diccionario["Clave"] == '1'){
        diccionario["Valor"] = this._translate.instant('perfil.lista-documentos.pasaporte')
       }
      if(diccionario["Clave"] == '2'){
        diccionario["Valor"] = this._translate.instant('perfil.lista-documentos.familia-numerosa')
      }
      if(diccionario["Clave"] == '3'){
        diccionario["Valor"] = this._translate.instant('perfil.lista-documentos.residente')
      }
    }
    return diccionarios
  }

  //Traduccion para varios idiomas de las opciones de Familia Numerosa
  traducirTipoFamiliaNumerosa(diccionarios: any){
    for(let diccionario of diccionarios){
      if(diccionario["Clave"] == '0'){
       diccionario["Valor"] = this._translate.instant('perfil.lista-documentos.tipo-famila-numerosa-general')
      }
      if(diccionario["Clave"] == '1'){
        diccionario["Valor"] = this._translate.instant('perfil.lista-documentos.tipo-familia-numerosa-especial')
       }
    }
    return diccionarios
  }


  //Traduccion para varios idiomas de las opciones de Tipo de Producto
  traducirTipoProducto(diccionarios: any){
    for(let diccionario of diccionarios){
      if(diccionario["Clave"] == '1'){
       diccionario["Valor"] = this._translate.instant('precliente.formulario.hotel.titulo')
      }
      if(diccionario["Clave"] == '2'){
        diccionario["Valor"] = this._translate.instant('precliente.formulario.vuelo.titulo')
       }
       if(diccionario["Clave"] == '3'){
        diccionario["Valor"] = this._translate.instant('precliente.formulario.rentacar.titulo')
       }
       if(diccionario["Clave"] == '4'){
        diccionario["Valor"] = this._translate.instant('precliente.formulario.barco.titulo')
       }
       if(diccionario["Clave"] == '5'){
        diccionario["Valor"] = this._translate.instant('precliente.formulario.tren.titulo')
       }
       if(diccionario["Clave"] == '9'){
        diccionario["Valor"] = this._translate.instant('precliente.formulario.transfer.titulo')
       }
    }
    return diccionarios
  }
}

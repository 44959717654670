<div class="card tarjeta" (click)="actualizarTabla()" #updateButton >
  <div class="card-body">
    <div class="row no-margin justify-content-between  seccion-datos-basicos">
      <div class="col-12 text-left align-self-center c-titulo c-titulo--md"  (click)="actualizarTabla()" #updateButton>
        {{"perfil.seccion.datos-personales" | translate}}
      </div>
      <div class="c-text c-text--md color-text-informacion g--padding-t-10">{{'precliente.formulario.recordatorio' | translate }}</div>
    </div>
    <!-- Formulario perfil usuario -->
    <form *ngIf="clienteFicha !== null && form" [formGroup]="form" (ngModelChange)="formularioCambiado()">

      <div class="row g-3 my-1">
       

        <!-- Nombre -->
        <div class="col-12 col-md-4">
          <app-input-material 
            id="nombre"
            type="text"
            label="{{'precliente.tabla.nombre' | translate }}"
            nombre="nombre"
            claseIcono="fas fa-user g--color-principal-11 me-1"
            [control]="form.get('nombre')"
            placeholder="{{'precliente.tabla.nombre' | translate }}"
            [isRequired]="true"
            [readonly]="lectura"
            
          >
          </app-input-material>
          <div *ngIf="form.get('nombre').errors   && form.get('nombre').touched" class="mensaje_validacion">
            <div *ngIf="form.get('nombre').hasError('required')">
             {{'precliente.formulario.validaciones.campo-obligatorio' | translate }}
            </div>
            <div *ngIf="form.get('nombre').hasError('pattern')">
             {{'precliente.formulario.validaciones.caracteres-alfabeticos' | translate }}
            </div>
            <div *ngIf= "form.get('nombre').hasError('minlength')">
              {{'precliente.formulario.validaciones.caracteres-minimo' | translate: {'valor': form.get('nombre').errors?.minlength?.requiredLength} }}
            </div>
            <div *ngIf= "form.get('nombre').hasError('maxlength')">
              {{'precliente.formulario.validaciones.caracteres-maximo' | translate: {'valor': form.get('nombre').errors?.maxlength?.requiredLength} }}
            </div>
          </div>
        </div>
        

        <!-- Apellidos -->
        <div class="col-12 col-md-4">
          <app-input-material
            id="apellidos"
            type="text"
            label="{{'precliente.tabla.apellidos' | translate }}"
            nombre="apellidos"
            claseIcono="fas fa-user g--color-principal-11 me-1"
            [control]="form.get('apellidos')"
            placeholder="{{'precliente.tabla.apellidos' | translate }}"
            [isRequired]="true"
            [readonly]="lectura"
          >
          </app-input-material>
        <div *ngIf="form.get('apellidos').errors && form.get('apellidos').touched" class="mensaje_validacion">
          <div *ngIf="form.get('apellidos').hasError('required')">
            {{'precliente.formulario.validaciones.campo-obligatorio' | translate }}
          </div>
          <div *ngIf="form.get('apellidos').hasError('pattern')">
            {{'precliente.formulario.validaciones.caracteres-alfabeticos' | translate }}
          </div>
          <div *ngIf= "form.get('apellidos').hasError('minlength')">
            {{'precliente.formulario.validaciones.caracteres-minimo' | translate: {'valor': form.get('apellidos').errors?.minlength?.requiredLength} }}
          </div>
          <div *ngIf= "form.get('apellidos').hasError('maxlength')">
            {{'precliente.formulario.validaciones.caracteres-maximo' | translate: {'valor': form.get('apellidos').errors?.maxlength?.requiredLength} }}
          </div>
        </div>
        
        </div>
        

        <!-- Genero -->
        <div class="col-12 col-md-4">
          <app-select-search-material
            [buscador]="false"
            [multiple]="false"
            [opciones]="diccionarios['Generos']"
            id="genero"
            clase="form-control form-control-md"
            claseIcono="fas fa-venus-mars g--color-principal-11 me-1"
            label="{{'precliente.formulario.label.genero' | translate }}"
            placeholderSelector="{{'precliente.formulario.label.genero' | translate }}"
            [control]="form.get('genero')"
            [readonly]="lectura"
            [isRequired]="true" 
          >
          </app-select-search-material>
          <div *ngIf="form.get('genero').errors && form.get('genero').touched" class="mensaje_validacion">
            <div *ngIf="form.get('genero').hasError('required')">
              {{'precliente.formulario.validaciones.campo-obligatorio' | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="row g-2 my-1">
        <!-- Fecha Nacimiento -->
        <div class="col-12 col-md-6">
          <app-calendar-material
            id="fechaNacimiento"
            label="{{'precliente.formulario.label.fechadenacimiento' | translate }}"
            [control]="form.get('fechaNacimiento')"
            [max]="maximaFechaNacimientoPermitida"
            [min]="minimaFechaNacimientoPermitida"
            [isRequired]="true"
            [isDisabled]="false"
            [readonly] = "true"
            [disabled] = "lectura"
            [mostrarIconoCalendario]="true"
          ></app-calendar-material>
          <div *ngIf="form.get('fechaNacimiento').errors   && form.get('fechaNacimiento').touched" class="mensaje_validacion">
            <div *ngIf="form.get('fechaNacimiento').hasError('required') || form.get('fechaNacimiento').hasError('matDatepickerParse')">
              {{'precliente.formulario.validaciones.campo-obligatorio' | translate }}
            </div>
            <div *ngIf="form.get('fechaNacimiento').hasError('fechaMenorEdad')">
              {{'perfil.error.fecha-nacimiento-18' | translate }}
            </div>
          </div>
        </div>

        <!-- Email -->
        <div class="col-12 col-md-6">     
          <app-input-material
            id="email"
            type="email"
            label="{{'precliente.formulario.label.email' | translate }}"
            nombre="email"
            claseIcono="fas fa-envelope-square g--color-principal-11 me-1"
            [control]="form.get('email')"
            placeholder="{{'precliente.formulario.label.email' | translate }}"
            [isRequired]="true"
            [readonly] = "true"
            ></app-input-material>
            <div *ngIf="form.get('email').errors && form.get('email').touched" class="mensaje_validacion">
              <div *ngIf="form.get('email').hasError('required')">
              {{'precliente.formulario.validaciones.campo-obligatorio' | translate }}
              </div>
              <div *ngIf="form.get('email').hasError('email')">
              {{'precliente.formulario.validaciones.email' | translate }}
              </div>
           </div>
        </div>
      </div>
      <div class="row g-2 my-1">
        <!-- Prefijo -->
        <div class="col-12 col-md-6">
          <app-select-search-material
          [buscador]="true"
          [multiple]="false"
          [opciones]="listaPrefijos"
          id="prefijo"
          clase="form-control form-control-md"
          claseIcono="fas fa-phone g--color-principal-11 me-1"
          label="{{'precliente.formulario.label.prefijo' | translate }}"
          placeholderBuscador="{{'precliente.formulario.label.prefijo' | translate }}"
          placeholderSelector="{{'precliente.formulario.label.prefijo' | translate }}"
          [control]="form.get('prefijo')"
          [readonly]="lectura"
          [isRequired]="true" 
        ></app-select-search-material>
        <div *ngIf="form.get('prefijo').errors && form.get('prefijo').touched" class="mensaje_validacion">
          <div *ngIf="form.get('prefijo').hasError('required')">
            {{'precliente.formulario.validaciones.campo-obligatorio' | translate }}
          </div>
        </div>

        </div>

        <!-- Teléfono -->
        <div class="col-12 col-md-6">
          <app-input-material 
            id="telefono"
            type="telefono"
            label="{{'precliente.formulario.label.telefono' | translate }}"
            nombre="telefono"
            claseIcono="fas fa-phone g--color-principal-11 me-1"
            [control]="form.get('telefono')"
            placeholder="{{'precliente.formulario.label.telefono' | translate }}"
            [isRequired]="true"
            [readonly]="lectura"
          ></app-input-material>
          <div *ngIf="form.get('telefono').errors && form.get('telefono').touched" class="mensaje_validacion">
            <div *ngIf="form.get('telefono').hasError('required')">
              {{'precliente.formulario.validaciones.campo-obligatorio' | translate }}
            </div>
            <div *ngIf="form.get('telefono').hasError('pattern')">
              {{'precliente.formulario.validaciones.caracteres-numericos' | translate }}
            </div>
            <div *ngIf= "form.get('telefono').hasError('minlength')">
              {{'precliente.formulario.validaciones.caracteres-minimo' | translate: {'valor': form.get('telefono').errors?.minlength?.requiredLength} }}
            </div>
            <div *ngIf= "form.get('telefono').hasError('maxlength')">
              {{'precliente.formulario.validaciones.caracteres-maximo' | translate: {'valor': form.get('telefono').errors?.maxlength?.requiredLength} }}
            </div>
          </div>
        </div>
      </div>

      <div class="row g-2 my-1">

        <!-- Pais -->
        <div class="col-12 col-md-6">
          <app-select-search-material 
            [buscador]="true"
            [multiple]="false"
            [opciones]="listaPaises"
            id="pais"
            clase="form-control form-control-md"
            claseIcono="fas fa-globe g--color-principal-11 me-1"
            label="{{'precliente.formulario.label.pais-residencia' | translate }}"
            placeholderBuscador="{{'precliente.formulario.label.pais-residencia' | translate }}"
            placeholderSelector="{{'precliente.formulario.label.pais-residencia' | translate }}"
            [control]="form.get('pais')"
            [isRequired]="true" 
            [readonly]="lectura" 
          ></app-select-search-material>
          <div *ngIf="form.get('pais').errors" class="mensaje_validacion">
            <div *ngIf="form.get('pais').hasError('required') && form.get('pais').touched ">
              {{'precliente.formulario.validaciones.campo-obligatorio' | translate }}
            </div>
          </div>
        </div>

        <!-- Direccion -->
        <div class="col-12 col-md-6">
          <app-input-material
          id="direccion"
          type="text"
          label="{{'admin.calendar.direccion' | translate }}"
          nombre="direccion"
          claseIcono="fas fa-map-marked-alt g--color-principal-11 me-1"
          [control]="form.get('direccion')"
          placeholder="{{'admin.calendar.direccion' | translate }}"
          [isRequired]="false"
          [readonly]="lectura"
        ></app-input-material>
        <div *ngIf=" form.get('direccion').errors && form.get('direccion').touched" class="mensaje_validacion">
          <div *ngIf=" form.get('direccion').hasError('pattern')">
            {{'precliente.formulario.validaciones.caracteres-invalidos' | translate }}
          </div>
          <div *ngIf= "form.get('direccion').hasError('minlength')">
            {{'precliente.formulario.validaciones.caracteres-minimo' | translate: {'valor': form.get('direccion').errors?.minlength?.requiredLength} }}
          </div>
          <div *ngIf= "form.get('direccion').hasError('maxlength')">
            {{'precliente.formulario.validaciones.caracteres-maximo' | translate: {'valor': form.get('direccion').errors?.maxlength?.requiredLength} }}
          </div>
        </div>
        </div>

      </div>

      <div class="row g-3 my-1">

        <!-- Ciudad -->
        <div class="col-12 col-md-4">
          <app-input-material 
          id="ciudad"
          type="text"
          label="{{'registro.ciudad' | translate }}"
          nombre="ciudad"
          claseIcono="fas fa-city g--color-principal-11 me-1"
          [control]="form.get('ciudad')"
          placeholder="{{'registro.ciudad' | translate }}"
          [isRequired]="false"
          [readonly]="lectura"
        ></app-input-material>
        <div *ngIf=" form.get('ciudad').errors && form.get('ciudad').touched" class="mensaje_validacion">
          <div *ngIf=" form.get('ciudad').hasError('pattern')">
            {{'precliente.formulario.validaciones.caracteres-alfabeticos' | translate }}
          </div>
          <div *ngIf= "form.get('ciudad').hasError('minlength')">
            {{'precliente.formulario.validaciones.caracteres-minimo' | translate: {'valor': form.get('ciudad').errors?.minlength?.requiredLength} }}
          </div>
          <div *ngIf= "form.get('ciudad').hasError('maxlength')">
            {{'precliente.formulario.validaciones.caracteres-maximo' | translate: {'valor': form.get('ciudad').errors?.maxlength?.requiredLength} }}
          </div>
        </div>
        </div>

        <!-- Codigo Postal -->
        <div class="col-12 col-md-4">
          <app-input-material
          id="codigoPostal"
          type="text"
          label="{{'registro.codigo-postal' | translate }}"
          nombre="codigoPostal"
          claseIcono="fas fa-arrow-circle-right g--color-principal-11 me-1"
          [control]="form.get('codigoPostal')"
          placeholder="{{'registro.codigo-postal' | translate }}"
          [isRequired]="false"
          [readonly]="lectura"
        ></app-input-material>
        <div *ngIf="form.get('codigoPostal').errors && form.get('codigoPostal').touched" class="mensaje_validacion">
          <div *ngIf="form.get('codigoPostal').hasError('pattern')">
            {{'precliente.formulario.validaciones.formato-no-esperado' | translate }}
          </div>
          <div *ngIf= "form.get('codigoPostal').hasError('minlength')">
            {{'precliente.formulario.validaciones.caracteres-minimo' | translate: {'valor': form.get('codigoPostal').errors?.minlength?.requiredLength} }}
          </div>
          <div *ngIf= "form.get('codigoPostal').hasError('maxlength')">
            {{'precliente.formulario.validaciones.caracteres-maximo' | translate: {'valor': form.get('codigoPostal').errors?.maxlength?.requiredLength} }}
          </div>
        </div>
        </div>

        <!-- Provincia -->
        <div class="col-12 col-md-4">
          <app-input-material
          id="provincia"
          type="text"
          label="{{'registro.provincia' | translate }}"
          nombre="provincia"
          claseIcono="fas fa-arrow-circle-right g--color-principal-11 me-1"
          [control]="form.get('provincia')"
          placeholder="{{'registro.provincia' | translate }}"
          [isRequired]="false"
          [readonly]="lectura"
        ></app-input-material>
        <div *ngIf=" form.get('provincia').errors && form.get('provincia').touched" class="mensaje_validacion">
          <div *ngIf=" form.get('provincia').hasError('pattern')">
            {{'precliente.formulario.validaciones.caracteres-alfabeticos' | translate }}
          </div>
          <div *ngIf= "form.get('provincia').hasError('minlength')">
            {{'precliente.formulario.validaciones.caracteres-minimo' | translate: {'valor': form.get('provincia').errors?.minlength?.requiredLength} }}
          </div>
          <div *ngIf= "form.get('provincia').hasError('maxlength')">
            {{'precliente.formulario.validaciones.caracteres-maximo' | translate: {'valor': form.get('provincia').errors?.maxlength?.requiredLength} }}
          </div>
        </div>
        </div>
      </div>

    </form>
  </div>
</div>

<!-- Seccion de documentos -->
<div class="card tarjeta g--margin-t-50 g--padding-b-20" (click)="actualizarTabla()" #updateButton >
  <div #seccion_documentos>
  </div>
</div>

<!-- Seccion de tarjetas de fidelizacion -->
<div class="card tarjeta g--margin-t-50 g--padding-b-20" (click)="actualizarTabla()" #updateButton >
  <div #Seccion_Tarjeta>
  </div>
</div>

<!-- Seccion de politicas y condiciones -->
<div *ngIf="mostrar"  class="card tarjeta g--margin-t-50">
  <div class="row no-margin justify-content-between seccion-documentos-tarjeta">
    <div class="col-7 text-left align-self-center c-titulo c-titulo--md">
     {{"perfil.seccion.politicas-condiciones" | translate}}
    </div>

    <!-- Boton para abrir modal y ver Politicas y condiciones -->
    <div class="col-3 text-center">
      <app-boton
        clase="c-button c-button--primary c-text g--padding-12 c-text c-text--md"
        [nombre]="'precliente.formulario.boton.ver-politicas'" 
        data-bs-toggle="modal"
        data-bs-target="#modalPoliticasCondiciones"
      ></app-boton>
    </div>
  </div>
</div>

<!-- Modal para visualizar las politicas y condiciones -->
<div class="modal fade" id="modalPoliticasCondiciones" tabindex="-1" aria-labelledby="modalPoliticasCondicionesLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" >{{"perfil.seccion.politicas-condiciones" | translate}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" id="seccionFormulario">
          <app-politicas-condiciones
            [lectura] = "true"
            [dentroModal]="true"
            [clienteFicha]="clienteFicha"
            [mostrarPoliticasCondiciones]="mostrarPoliticasCondiciones"
            >
          </app-politicas-condiciones>
      </div>
    </div>
  </div>
</div>

<div class="row no-margin justify-content-between seccion-documentos-tarjeta">
    <div class="col-7 g--padding-t-16 text-left align-self-center c-titulo c-titulo--md " (click)="actualizarTabla()" #updateButton>
      {{'perfil.lista-tarjetas.tarjeta-fidelizacion' | translate}}
    </div>
  
    <div class="col-3 text-center">
      <!-- Boton crear documento -->
      <app-boton
        clase="c-button c-button--primary c-text g--padding-12 c-text c-text--md"
        [nombre]="'perfil.editar-perfil.anyadir'"
        (click)="crearFidelizacion()"
        [lectura]="lectura"
      ></app-boton>
    </div>
</div>
<div  class="container">
  <form [formGroup]="form" class="form-container">
    <div class="row">
      <mat-table
        #table
        class="mat-elevation-z0"
        [dataSource]="dataSource">

        <div class="row g-3 my-1">
          <ng-container matColumnDef="TipoProducto">
            <mat-cell *matCellDef="let row" class="TipoProducto-cell">
              <div class="col-12 col-md-10 col-lg-10">
                <ng-container [formGroup]="row">
                  <!-- Select para todos los tipos de productos-->
                  <app-select-search-material
                    [buscador]="false"
                    [multiple]="false"
                    [opciones]="TiposProductos"
                    id="TipoProducto"
                    clase="form-control form-control-md"
                    claseIcono=""
                    label="{{'perfil.lista-tarjetas.tipo-producto' | translate }}"
                    placeholderBuscador="{{'perfil.lista-tarjetas.tipo-producto' | translate }}"
                    placeholderSelector="{{'perfil.lista-tarjetas.tipo-producto' | translate }}"
                    [control]="row.get('TipoProducto')"
                    (opcionSeleccionadaEvent)="seleccionTipoProducto($event, row)"
                    [readonly]="lectura"
                    [isRequired]="true"
                  >
                  </app-select-search-material>
                  <mat-error *ngIf="row.get('TipoProducto').hasError('required')">
                    {{'precliente.formulario.validaciones.campo-obligatorio' | translate }}
                  </mat-error>
                </ng-container>
              </div>
            </mat-cell>
          </ng-container>
        
          <ng-container matColumnDef="Compania">
            <mat-cell *matCellDef="let row" class="Compania-cell">
              <div class="col-12 col-md-10 col-lg-10">
                <ng-container [formGroup]="row">
                  <div class="campo">
                    <!-- Select para todos los tipos menos Aereo -->
                    <div *ngIf="row.get('TipoProducto').value != ETipoProducto.Aereo" >
                      <app-select-search-material
                        [buscador]="false"
                        [multiple]="false"
                        [opciones]="convertirCompanias(row.get('TipoProducto').value)"
                        id="CompaniasMenosAereo"
                        clase="form-control form-control-md"
                        claseIcono=""
                        label="{{'perfil.lista-tarjetas.compania' | translate }}"
                        placeholderBuscador="{{'perfil.lista-tarjetas.compania' | translate }}"
                        placeholderSelector="{{'perfil.lista-tarjetas.compania  ' | translate }}"
                        [control]="row.get('CompaniasMenosAereo')"
                        (opcionSeleccionadaEvent)="seleccionTipoCompanyia(row)"
                        [readonly]="lectura"
                        [isRequired]="true"
                      >
                      </app-select-search-material>
                      <mat-error *ngIf="row.get('CompaniasMenosAereo').hasError('required')">
                        {{'precliente.formulario.validaciones.campo-obligatorio' | translate }}
                      </mat-error>
                    </div> 
                  
                 
                    <!-- Select cuando es de tipo Aereo -->
                    <div *ngIf="row.get('TipoProducto').value == ETipoProducto.Aereo">
                      <app-select-search-material
                        [buscador]="false"
                        [multiple]="false"
                        [opciones]="TipoCompania"
                        id="CompaniaAerea"
                        clase="form-control form-control-md"
                        claseIcono=""
                        label="{{'perfil.lista-tarjetas.compania' | translate }}"
                        placeholderBuscador="{{'perfil.lista-tarjetas.compania' | translate }}"
                        placeholderSelector="{{'perfil.lista-tarjetas.compania' | translate }}"
                        [control]="row.get('CompaniaAerea')"
                        (opcionSeleccionadaEvent)="seleccionTipoCodigo($event, row)"
                        [isRequired]="true"
                        [readonly]="lectura"
                      >
                      </app-select-search-material>
                      <mat-error *ngIf="row.get('CompaniaAerea').hasError('required')">
                        {{'precliente.formulario.validaciones.campo-obligatorio' | translate }}
                      </mat-error>
                    </div>  

                  </div>
                </ng-container>
              </div>
            </mat-cell>
          </ng-container>

          <!-- Codigo para todos los tipos menos Aereo -->
          <ng-container matColumnDef="OtroCodigo">
            <mat-cell *matCellDef="let row" class="OtroCodigo-cell" [ngClass]="{'oculto': !(row.get('TipoProducto').value != ETipoProducto.Aereo &&  row.get('CompaniasMenosAereo').value == OTRO)}">
              <div class="col-12 col-md-10 col-lg-10">
                <ng-container [formGroup]="row">
                  <div  *ngIf="row.get('TipoProducto').value != ETipoProducto.Aereo &&  row.get('CompaniasMenosAereo').value == OTRO">
                    <app-input-material
                      id="OtroCodigo"
                      class="campo"
                      type="text"
                      label= "{{'perfil.lista-tarjetas.compania' | translate }}"
                      nombre="Otro"
                      claseIcono=""
                      [control]="row.get('OtroCodigo')"
                      placeholder="{{'perfil.lista-tarjetas.compania' | translate }}"
                      [isRequired]="true"   
                      [readonly]="lectura"
                    >
                    </app-input-material>
                    <mat-error *ngIf="row.get('OtroCodigo').errors?.['maxlength'] && row.get('OtroCodigo').touched">
                      {{'precliente.formulario.validaciones.caracteres-maximo' | translate: {'valor': row.get('OtroCodigo').errors.maxlength.requiredLength} }}
                    </mat-error> 
                    <mat-error *ngIf="row.get('OtroCodigo').hasError('required')">
                      {{'precliente.formulario.validaciones.campo-obligatorio' | translate }}
                    </mat-error>
                  </div>
                </ng-container>
              </div>
            </mat-cell>
          </ng-container>

     

          <!-- Select NombreAlianza cuando compañia es alianza -->
          <ng-container matColumnDef="NombreAlianza">
            <mat-cell *matCellDef="let row" class="NombreAlianza-cell" [ngClass]="{'oculto': !(row.get('CompaniaAerea').value == ETipoAlianza.Alianza && row.get('TipoProducto').value == ETipoProducto.Aereo)}">
              <div class="col-12 col-md-10 col-lg-10">
                <ng-container [formGroup]="row">
                  <div class="campo-select" *ngIf="row.get('CompaniaAerea').value == ETipoAlianza.Alianza && row.get('TipoProducto').value == ETipoProducto.Aereo">
                    <app-select-search-material
                      [buscador]="false"
                      [multiple]="false"
                      [opciones]="convertirAlianzas()"
                      id="NombreAlianza"
                      clase="form-control form-control-md"
                      claseIcono=""
                      label="{{'perfil.lista-tarjetas.nombre-alianza' | translate }}"
                      placeholderBuscador="{{'perfil.lista-tarjetas.nombre-alianza' | translate }}"
                      placeholderSelector="{{'perfil.lista-tarjetas.nombre-alianza' | translate }}"
                      [control]="row.get('NombreAlianza')"
                      (opcionSeleccionadaEvent)="seleccionarOpcionMiembroAlianza($event)"
                      [isRequired]="true"
                      [readonly]="lectura"
                    >
                    </app-select-search-material>
                    <mat-error *ngIf="row.get('NombreAlianza').hasError('required')">
                      {{'precliente.formulario.validaciones.campo-obligatorio' | translate }}
                    </mat-error>
                  </div> 
                </ng-container>
              </div>
            </mat-cell>
          </ng-container>

          <!-- Codigo alianza cuando compañia es alianza-->
          <ng-container matColumnDef="CodigoAlianza">
            <mat-cell *matCellDef="let row" class="CodigoAlianza-cell" [ngClass]="{'oculto': !(row.get('CompaniaAerea').value == ETipoAlianza.Alianza && row.get('NombreAlianza').value > 0 && row.get('TipoProducto').value == ETipoProducto.Aereo)}">
              <div class="col-12 col-md-10 col-lg-10">
                <ng-container [formGroup]="row">
                  <div *ngIf="row.get('CompaniaAerea').value == ETipoAlianza.Alianza && row.get('NombreAlianza').value > 0 && row.get('TipoProducto').value == ETipoProducto.Aereo">
                    <app-select-search-material
                      [buscador]="false"
                      [multiple]="false"
                      [opciones]="listaMiembrosAlianza" 
                      id="CodigoAlianza"
                      clase="form-control form-control-md"
                      claseIcono=""
                      label="{{'perfil.lista-tarjetas.codigo-alianza' | translate }}"
                      placeholderBuscador="{{'perfil.lista-tarjetas.codigo-alianza' | translate }}"
                      placeholderSelector="{{'perfil.lista-tarjetas.codigo-alianza' | translate }}"
                      [control]="row.get('CodigoAlianza')"
                      [isRequired]="true"
                      [readonly]="lectura"
                    >
                    </app-select-search-material>
                    <mat-error *ngIf="row.get('CodigoAlianza').hasError('required')">
                      {{'precliente.formulario.validaciones.campo-obligatorio' | translate }}
                    </mat-error>
                  </div>  
                </ng-container>
              </div>
            </mat-cell>
          </ng-container>

          <!-- Aereolineas cuando compañia es codigo en caso Aereo -->
          <ng-container matColumnDef="CodigoAlianzaAereo">
            <mat-cell *matCellDef="let row" class="CodigoAlianzaAereo-cell" [ngClass]="{'oculto': !(row.get('CompaniaAerea').value == ETipoAlianza.Codigo && row.get('TipoProducto').value == ETipoProducto.Aereo)}">
              <div class="col-12 col-md-10 col-lg-10">
                <ng-container [formGroup]="row">
                  <div class="campo-select" *ngIf="row.get('CompaniaAerea').value == ETipoAlianza.Codigo && row.get('TipoProducto').value == ETipoProducto.Aereo">
                    <app-select-search-material
                      [buscador]="true"
                      [multiple]="false"
                      [opciones]="ListaAereoLineas()" 
                      id="CodigoAlianzaAereo"
                      clase="form-control form-control-md"
                      claseIcono=""
                      label="{{'perfil.lista-tarjetas.compania' | translate }}"
                      placeholderBuscador="{{'perfil.lista-tarjetas.compania' | translate }}"
                      placeholderSelector="{{'perfil.lista-tarjetas.compania' | translate }}"
                      [control]="row.get('CodigoAlianzaAereo')"
                      (opcionSeleccionadaEvent)="seleccionCodigoAlianzaAereo($event, row)"
                      [isRequired]="true"
                      [readonly]="lectura"
                    >
                    </app-select-search-material>
                    <mat-error *ngIf="row.get('CodigoAlianzaAereo').hasError('required')">
                      {{'precliente.formulario.validaciones.campo-obligatorio' | translate }}
                    </mat-error>
                  </div>
                </ng-container>
              </div>
            </mat-cell>
          </ng-container>

          <!-- Codigo cuando compañia es codigo en caso Aereo -->
          <ng-container matColumnDef="CodigoAereo">
            <mat-cell *matCellDef="let row" class="CodigoAereo-cell" [ngClass]="{'oculto': !(row.get('CodigoAlianzaAereo').value == OTRO &&  (row.get('CompaniaAerea').value == ETipoAlianza.Codigo && row.get('TipoProducto').value == ETipoProducto.Aereo))}">
              <div class="col-12 col-md-10 col-lg-10">
                <ng-container [formGroup]="row">
                  <div class="campo-select" *ngIf="row.get('CodigoAlianzaAereo').value == OTRO && row.get('TipoProducto').value == ETipoProducto.Aereo">
                    <app-input-material
                      id="CodigoAereo"
                      class="campo"
                      type="text"
                      label="{{'perfil.lista-tarjetas.codigo' | translate }}"
                      nombre="CodigoAereo"
                      claseIcono=""
                      [control]="row.get('CodigoAereo')"
                      placeholder="{{'perfil.lista-tarjetas.codigo' | translate }}"
                      [isRequired]="true"
                      [readonly]="lectura"
                    >
                    </app-input-material>
                    <mat-error *ngIf="row.get('CodigoAereo').errors?.['maxlength'] && row.get('CodigoAereo').touched">
                      {{'precliente.formulario.validaciones.caracteres-maximo' | translate: {'valor': row.get('CodigoAereo').errors.maxlength.requiredLength} }}
                    </mat-error>
                    <mat-error *ngIf="row.get('CodigoAereo').hasError('required')">
                      {{'precliente.formulario.validaciones.campo-obligatorio' | translate }}
                    </mat-error>
                  </div>
                </ng-container>
              </div>
            </mat-cell>
          </ng-container>

          <!-- Numeracion-->
          <ng-container matColumnDef="Numeracion">
            <mat-cell *matCellDef="let row" class="Numeracion-cell">
              <div class="col-12 col-md-10 col-lg-10">
                <ng-container [formGroup]="row">
                  <div>
                    <!-- Input con el número de código -->
                    <app-input-material
                      id="Numeracion"
                      class="campo"
                      type="text"
                      label="{{'perfil.lista-documentos.tabla.numero' | translate }}"
                      nombre="Numeracion"
                      claseIcono=""
                      [control]="row.get('Numeracion')"
                      placeholder="{{'perfil.lista-documentos.tabla.numero' | translate }}"
                      [isRequired]="true"
                      [readonly]="lectura"
                    >
                    </app-input-material>
                    <mat-error *ngIf="row.get('Numeracion').errors?.['maxlength'] && row.get('Numeracion').touched">
                      {{'precliente.formulario.validaciones.caracteres-maximo' | translate: {'valor': row.get('Numeracion').errors.maxlength.requiredLength} }}
                    </mat-error>
                    <mat-error *ngIf="row.get('Numeracion').hasError('required')">
                      {{'precliente.formulario.validaciones.campo-obligatorio' | translate }}
                    </mat-error>
                    <mat-error *ngIf="row.get('Numeracion').hasError('pattern')">
                      {{'precliente.formulario.validaciones.caracteres-alfanumericos' | translate }}
                    </mat-error>
                  </div>
                </ng-container>
              </div>
            </mat-cell>
          </ng-container>
        </div>

          <ng-container matColumnDef="FechaCaducidad">
            <!-- <mat-cell *matCellDef="let row" class="TipoDocumento-cell" class="cell-big"> Aplicar clase para hacer la celda mas grande-->
            <mat-cell *matCellDef="let row" class="FechaCaducidad-cell">
              <div class="col-12 col-md-10 col-lg-10">
                <ng-container [formGroup]="row">
                  <div class="campo">
                    <!-- Input de calendario para la fecha de caducidad -->
                    <app-calendar-material
                      id="FechaCaducidad"
                      label="{{'perfil.lista-documentos.fecha-caducidad' | translate }}"
                      [control]="row.get('FechaCaducidad')"
                      [max]="maximaFechaNacimientoPermitida"
                      [min]="minimaFechaNacimientoPermitida"
                      [isRequired]="false"
                      [isDisabled]="false"
                      [readonly]="true"
                      [disabled] = "lectura"
                      [form]="row"
                      [vaciarFecha]="vaciarFecha"
                      [mostrarBoton]="true"
                      [mostrarIconoCalendario]="false"
                    ></app-calendar-material>
                    <mat-error *ngIf="row.get('FechaCaducidad').hasError('fechaInvalida')">
                      {{'precliente.formulario.validaciones.fecha-no-menor-actual' | translate }}
                    </mat-error>
                  </div>
                </ng-container>
              </div>
            </mat-cell>
          </ng-container>
         
          <ng-container matColumnDef="Notas">
            <!-- <mat-cell *matCellDef="let row" class="TipoDocumento-cell" class="cell-big"> -->
            <mat-cell *matCellDef="let row" class="Notas-cell">
              <div class="col-12 col-md-10 col-lg-10">
                <ng-container [formGroup]="row">
                  <mat-form-field class="campo"  appearance="outline" class="custom-outline width-medium">

                    <!-- Textarea para posibles notas de usuarios -->
                    <textarea matInput name="textarea"  class="textarea" formControlName="Notas" placeholder="{{'perfil.lista-tarjetas.notas' | translate }}" rows="1" cols="20" [readonly]="lectura"></textarea>
                    <mat-error *ngIf="row.get('Notas').errors?.['maxlength'] && row.get('Notas').touched">
                      {{'precliente.formulario.validaciones.caracteres-maximo' | translate: {'valor': row.get('Notas').errors.maxlength.requiredLength} }}
                    </mat-error>
                  </mat-form-field>
               </ng-container>
              </div>
            </mat-cell>
          </ng-container>
          
          
          <!-- Acciones para realizar por el usuario como eliminar la tarjeta de fidelizacion -->
          <ng-container matColumnDef="Acciones">
            <mat-cell *matCellDef="let documento; let i = index" class="Acciones-cell cell-small">
              <div class="col-12 col-md-10 col-lg-10">
                <button  class="c-button-papelera full-width-button" *ngIf="!lectura" mat-icon-button (click)="cancelarEdicion(i)" lectura="lectura">
                  <mat-icon >delete_outline</mat-icon>
                </button>
              </div>
            </mat-cell>
          </ng-container>

        <!-- Row Declarations -->
        <mat-row *matRowDef="let row; columns: columnas" class="sin-borde"></mat-row>

      </mat-table>
    </div>
  </form>
</div>
  

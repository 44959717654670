<div class="c-card--1 g--margin-t-15">
    <div class="c-card__title">
        <h2>{{"precliente.formulario.perfildelviajero.titulo" | translate}}</h2>
    </div>
    <div class="c-card__body g--margin-b-30 row">
        <!-- Grupo Fumador -->
        <div class="col-12 col-lg-3 g--margin-r-30 l-flex l-flex--direction-column l-flex--space-between">

            <div class="g--margin-t-30 ">
                <app-grupo-preferencia
                    #Grupo_Preferencia_Fumador
                    [TituloGrupo]="'precliente.formulario.fumador'"
                    [ListaPreferencias] = "obtenerOpcionesPreferencia(preferencias,TipoPreferencia.Fumador)"
                    posicionFlex="c-section-altnative__body g--margin-t-40 l-flex"
                    posicionMargin="l-flex g--margin-r-15"
                    [multiple]="obtenerCamposMultiplesPreferencia(preferencias,TipoPreferencia.Fumador)"
                    [required]="obtenerCamposObligatoriosPreferencia(preferencias,TipoPreferencia.Fumador)"> 
                </app-grupo-preferencia>
            </div>

        </div>

        <!-- Grupo Movilidad -->
        <div class="g--margin-t-30 col-12 col-lg-4 g--margin-r-30">
            <app-grupo-preferencia
                #Grupo_Preferencia_Movilidad
                [TituloObservacion]="'Observaciones'"
                [TituloGrupo]="'precliente.formulario.necesidaddemovilidad'"
                [ListaPreferencias]="obtenerOpcionesPreferencia(preferencias,TipoPreferencia.Movilidad)"
                [RequiereObservacion]="true"
                [ClaseAdicional]="'ContenedorGrupoMovilidad'"
                [multiple]="obtenerCamposMultiplesPreferencia(preferencias,TipoPreferencia.Movilidad)"
                [required]="obtenerCamposObligatoriosPreferencia(preferencias,TipoPreferencia.Movilidad)">
            </app-grupo-preferencia>
        </div>
       
    </div>
   
</div>

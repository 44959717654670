<div class="c-card g--margin-t-15">
    <div class="c-card__body g--margin-b-30 row">

        <!-- Grupo Preferencias asientos Tren -->
        <div class="g--margin-t-30 col-12 col-lg-4 l-flex l-flex--direction-column l-flex--space-between">
            <app-grupo-preferencia
                #Grupo_Preferencia_Tren_Asientos
                [TituloGrupo]="'precliente.formulario.vuelo.preferenciasenasientos'"
                [ListaPreferencias]="obtenerOpcionesPreferencia(preferencias,TipoPreferencia.PreferenciaAsientosTren)"
                [multiple] ="obtenerCamposMultiplesPreferencia(preferencias,TipoPreferencia.PreferenciaAsientosTren)"
                [required]="obtenerCamposObligatoriosPreferencia(preferencias,TipoPreferencia.PreferenciaAsientosTren)"
                posicionFlex="c-section__body l-flex g--margin-t-40 l-flex l-flex--direction-column"
                posicionMargin="l-flex g--margin-b-24">
            </app-grupo-preferencia>

            <!-- Grupo Observacion de Tren -->
            
       
        </div>
        <div class="g--margin-t-30 col-12 col-lg-4 l-flex l-flex--direction-column l-flex--space-between">
        <app-grupo-preferencia
                #Grupo_Preferencia_Tren_Mesa
                [TituloGrupo]="'precliente.formulario.vuelo.preferenciasenasientos'"
                [ListaPreferencias]="obtenerOpcionesPreferencia(preferencias,TipoPreferencia.PreferenciaMesaTren)"
                [multiple] ="obtenerCamposMultiplesPreferencia(preferencias,TipoPreferencia.PreferenciaMesaTren)"
                [required]= "obtenerCamposObligatoriosPreferencia(preferencias,TipoPreferencia.PreferenciaMesaTren)"
                posicionFlex="c-section__body l-flex g--margin-t-40 l-flex l-flex--direction-column"
                posicionMargin="l-flex g--margin-b-24">
            </app-grupo-preferencia>
        </div>
       

        <div class="g--margin-t-30 col-12 col-lg-4 l-flex l-flex--direction-column l-flex--space-between">
            <app-grupo-preferencia
                #Grupo_Preferencia_Tren_SentidoMarcha
                [TituloGrupo]="'precliente.formulario.tren.sentidomarcha'"
                [ListaPreferencias]="obtenerOpcionesPreferencia(preferencias,TipoPreferencia.SentidoMarchaTren)"
                [multiple] ="obtenerCamposMultiplesPreferencia(preferencias,TipoPreferencia.SentidoMarchaTren)"
                [required]="obtenerCamposObligatoriosPreferencia(preferencias,TipoPreferencia.SentidoMarchaTren)"
                posicionFlex="c-section__body l-flex g--margin-t-40 l-flex l-flex--direction-column"
                 posicionMargin="l-flex g--margin-b-24">
            </app-grupo-preferencia>
        </div>

         <!-- Grupo Sentido de Marcha de Tren -->
         <div class="g--margin-t-30 col-12 col-lg-4 g--margin-r-30">
            <div class="g--margin-t-30 c-section-altnative c-table">
                <div class="c-section-alternative__header">
                    <app-observaciones
                    #Grupo_Preferencia_Tren_Observaciones
                    [(textareaValue)] = "texto"
                    (textareaValueChange)="actualizarValorObservacion($event)"
                    ></app-observaciones>
                </div>
            </div>
        </div>
    </div>
</div>
